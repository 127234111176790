@import url('../config/core.less');

.button{
    border-radius: 30px;
}

#banner{
    padding: 180px 0;

    &.page{
        padding: 20px 0;
        min-height: 300px;

        .content{
            height: 290px;
            row-gap: 30px;
            justify-content: center;

            h1{
                font-size: 50px;

                @media(max-width: 991px){
                    font-size: 30px;
                }

            }

        }

        @media(max-width: 991px){
            height: auto;
            padding: 50px 0;
        }

    }

    .content{
        row-gap: 40px;
        display: flex;
        flex-direction: column;

        h1{
            color: #fff;
            font-size: 70px;
            font-weight: 700;

            @media(max-width: 991px){
                font-size: 30px;
            }

        }

        p{
            color: #fff;
            max-width: 60%;
            font-size: 26px;
            font-weight: 400;

            @media(max-width: 991px){
                max-width: 80%;
                font-size: 22px;
            }

            @media(max-width: 767px){
                max-width: 100%;
                font-size: 18px;
            }

        }

        a{
            margin: 0;
        }

    }

    @media(max-width: 991px){
        padding: 50px 0;
    }

}

#additional-footer{
    padding: 75px 0;

    .blocks{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .block{
            gap: 25px;
            width: 33%;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            flex-direction: row;

            .icon{
                padding: 10px 15px;
                align-self: center;
                border: 3px solid #fff;

                i{
                    color: #fff;
                    font-size: 30px;
                }

            }

            .content{

                p, a{
                    color: #fff;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 28px;
                    text-decoration: none;

                    @media(max-width: 991px){
                        font-size: 16px;
                        line-height: 26px;
                    }

                }

            }

            @media(max-width: 991px){
                width: 100%;
            }

        }

        @media(max-width: 991px){
            row-gap: 30px;
            flex-direction: column;
        }

    }
    
}