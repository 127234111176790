@grey: #F9FAF8;
@footer-bg: @grey;

@light_grey: #D9D9D9;
@input_border: @light_grey;

* {
    box-sizing: border-box;
}

body{
    margin: 0;
}

h1, h2, h3, h4, h5, p, span{
    margin: 0;
    padding: 0;
}

ul{
    margin: 0;
    padding: 0;
}

li{

    a{
        text-decoration: none;
    }

}

.button{
    height: 50px;
    width: 211px;
    display: flex;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    border-radius: 10px;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    

    @media(max-width: 991px){
        width: 298px;
        font-size: 18px;
        max-width: 100%;
    }

}

.container{
    width: 1365px;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 15px;
}

#navigation{
    top: 0;
    z-index: 1100;
    position: sticky;
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);

    nav{
        height: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    
        .logo{
            display: flex;
            max-width: 260px;
            max-height: 75px;
    
            img{
                max-width: 260px;
                max-height: 75px;
                object-fit: cover;
            }

            a{
                display: flex;
                color: #000;
                font-size: 26px;
                text-decoration: none;
                text-transform: uppercase;
            }
    
        }
    
        .items{
            display: flex;
            flex-direction: row;

            ul{
                display: flex;
                column-gap: 66px;
                align-items: center;
                flex-direction: row;

                li{
                    list-style: none;

                    a{
                        font-size: 20px;
                        font-weight: 700;
                    }

                    @media(max-width: 991px){
                        margin-bottom: 20px;
                    }

                }

                span{
                    font-size: 20px;
                    font-weight: 700;
                    border-radius: 5px;
                    padding: 10px 20px;

                    &:hover{
                        cursor: pointer;
                    }

                }

                @media(max-width: 991px){
                    top: 100px;
                    left: -15px;
                    z-index: 1100;
                    display: none;
                    row-gap: 30px;
                    padding: 15px;
                    position: absolute;
                    flex-direction: column;
                    width: calc(100% + 30px);
                    background-color: #fff;
                }

            }

            .mobile-toggle{
                display: none;

                &::before{
                    font-size: 32px;
                    content: '\f0c9';
                    font-weight: 900;
                    font-family: "Font Awesome 6 Pro";
                }

                &:hover{
                    cursor: pointer;
                }

                @media(max-width: 991px){
                    display: flex;
                }

            }

        }

        @media(max-width: 991px){
            position: relative;
        }
    
    }

}

#banner{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    .overlay{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background-color: rgba(41, 41, 41, 0.3);
    }

    .content{
        z-index: 100;
        position: relative;

        ul{

            li{
                color: #fff;
            }

        }

        .logo{
            width: 284px;
            height: 155px;
            max-width: 100%;
        }

    }

}

#navigation-buttons{
    padding: 50px 0 50px 0;

    .buttons{
        gap: 30px;
        display: flex;
        flex-wrap: wrap;

        a{
            display: flex;
            padding: 15px 30px;
            align-items: center;
            text-decoration: none;
            justify-content: center;
        }

    }

}

#content-blocks{
    padding: 70px 0;

    &.temp-2{
        display: flex;
        row-gap: 75px;
        flex-direction: column;
    }

    .container{
        row-gap: 75px;
        display: flex;
        flex-direction: column;

        @media(max-width: 991px){
            row-gap: 40px;
        }

    }

    .block{
        margin-bottom: 35px;
        row-gap: 30px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 65px;
        flex-direction: row;
        align-items: center;

        .image{
            order: 1;
            flex-shrink: 0;
            width: calc(50% - (65px / 2));

            &.right{
                order: 2;

                @media(max-width: 767px){
                    order: 1;
                }

            }

            img{
                width: 100%;
            }

            @media(max-width: 991px){
                width: 100%;
            }

        }

        .content{
            order: 1;
            display: flex;
            row-gap: 30px;
            flex-direction: column;
            width: calc(50% - (65px / 2));

            h3{
                font-size: 30px;
                font-weight: 700;

                @media(max-width: 991px){
                    font-size: 20px;
                }

            }

            p{
                font-size: 18px;

                @media(max-width: 991px){
                    font-size: 16px;
                }

            }

            ul{
                row-gap: 10px;
                display: flex;
                flex-direction: column;

                li{
                    color: #000000;
                    font-size: 18px;
                    list-style: disc;
                    margin: 0 0 0 15px;

                    @media(max-width: 991px){
                        font-size: 16px;
                    }

                }

            }

            ol{
                row-gap: 10px;
                display: flex;
                flex-direction: column;

                li{
                    font-size: 18px;
                    list-style: disc;
                    margin: 0 0 0 15px;

                    @media(max-width: 991px){
                        font-size: 16px;
                    }

                }

            }

            .button{
                margin: 0;

                @media(max-width: 991px){
                    font-size: 18px;
                }

            }

            &.no-image{
                width: 100%;
            }

            @media(max-width: 991px){
                width: 100%;
            }

        }

        form{
            order: 3;
            gap: 30px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 50px 45px;
            border-radius: 10px;
            flex-direction: row;
            scroll-margin-top: 155px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
            
            .title{
                display: flex;
                row-gap: 10px;
                flex-direction: column;
            }

            .form-group{
                display: flex;
                row-gap: 10px;
                width: calc(50% - 5px);
                flex-direction: column;

                &.full{
                    width: 100%;
                }

                &.half{
                    width: calc(50% - 15px);
                }

                label{
                    font-size: 18px;
                    font-weight: 700;
                }

                input{
                    height: 50px;
                    padding: 0 18px;
                    border-radius: 10px;
                    border: 1px solid @input_border;
                }

                textarea{
                    resize: none;
                    height: 143px;
                    padding: 18px;
                    border-radius: 10px;
                    border: 1px solid @input_border;
                }

                select{
                    height: 50px;
                    padding: 0 18px;
                    border-radius: 10px;
                    border: 1px solid @input_border;
                }

                @media(max-width: 767px){
                    width: 100%;
                }

            }

            button{
                width: 211px;
                height: 50px;
                border: none;
                font-size: 20px;
                color: #ffffff;
                font-weight: 700;
                margin: 0 0 0 auto;
                border-radius: 10px;

                @media(max-width: 767px){
                    width: 100%;
                }

            }

        }

        @media(max-width: 991px){
            gap: 25px;
            flex-direction: column;
        }

    }

    @media(max-width: 991px){
        padding: 40px 0;
    }

}

#additional{
    position: relative;
    padding: 130px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.go-white {
        color: #fff;
    }

    &.no-padding-bottom{
        padding: 130px 0 0 0;
    }

    .container{
        gap: 50px;
        display: flex;
        flex-direction: column;
    }

    h1{
        font-size: 50px;
        font-weight: 700;
    }

    p{
        font-size: 18px;
        margin: 0 0 15px 0;
    }

}

#booking-widget{
    padding: 50px 0;
}

footer{
    padding: 75px 0;
    background-color: @footer-bg;

    .container{
        display: flex;
        column-gap: 15px;
        flex-direction: row;
        justify-content: space-between;

        .company{
            width: 100%;
            row-gap: 40px;
            display: flex;
            flex-direction: column;

            .logo{
                display: flex;
                max-width: 260px;
                max-height: 75px;

                img{
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: scale-down;
                    object-position: left center;
                }

                p{
                    font-size: 26px;
                    color: #000000;
                    text-transform: uppercase;
                }

            }

            .accreditations{
                gap: 15px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
            }

            .address{
                
                p{
                    font-size: 16px;
                    color: #000000;
                }
                
            }

        }

        .pages{
            width: 100%;

            ul{
                display: flex;
                row-gap: 25px;
                flex-direction: column;

                li{
                    list-style: none;

                    a{
                        font-size: 20px;
                        font-weight: 700;
                        color: #000000;
                    }

                }

            }

        }

        .links{
            width: 25%;
            display: flex;
            row-gap: 30px;
            flex-shrink: 0;
            text-align: right;
            flex-direction: column;

            .social{
                display: flex;
                column-gap: 16px;
                flex-direction: row;
                justify-content: flex-end;

                a{
                    font-size: 24px;
                    color: #000000;
                    text-decoration: none;
                }

                @media(max-width: 991px){
                    justify-content: flex-start;
                }

            }

            .content{
                height: 100%;
                display: flex;
                row-gap: 30px;
                flex-direction: column;

                p{
                    font-size: 16px;
                    color: #000000;
                    font-weight: 400;
                }

                a{
                    font-size: 16px;
                    font-weight: 700;
                    color: #000000;
                    text-decoration: none;
                }

            }

            .site_by{
                margin: auto 0 0 0;

                p{
                    font-size: 16px;
                    font-weight: 400;

                    a{
                        font-weight: 700;
                        text-decoration: underline;
                    }

                }

            }

            @media(max-width: 991px){
                width: 100%;
                text-align: left;
            }

        }

        @media(max-width: 991px){
            row-gap: 40px;
            flex-direction: column;
        }

    }

}

.message{
    width: 100%;
    color: #000;
    padding: 15px;
    border-radius: 10px;

    &.success{
        border: 1px solid #2BDE00;
        background-color: rgba(210, 248, 222, 0.3);
    }

}

#testimonials{
    margin: 95px 0;

    .title{
        text-align: center;
        margin: 0 0 50px 0;

        h2{
            font-size: 50px;
            font-weight: 700;

            @media(max-width: 991px){
                font-size: 30px;
            }

        }

        @media(max-width: 991px){
            margin: 0 0 40px 0;
        }

    }

    .testimonial{
        margin: 5px;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

        p{
            font-size: 18px;
            font-weight: 400;

            &.who{
                margin: 0 0 10px 0;
            }

            &.who, &.company{
                font-weight: 700;
            }

            @media(max-width: 991px){
                font-size: 16px;
            }
            
        }

        hr{
            height: 5px;
            width: 69px;
            border: none;
            margin: 15px 0;
        }

    }

    .slick-dots{
        gap: 5px;
        padding: 0;
        display: flex;
        margin: 50px 0 0 0;
        flex-direction: row;
        justify-content: center;

        li{
            width: 10px;
            height: 10px;
            list-style: none;
            border-radius: 50%;
            background-color: #000;

            button{
                display: none;
            }

            &:hover{
                cursor: pointer;
            }

        }

        @media(max-width: 991px){
            margin: 25px 0 0 0;
        }

    }

    @media(max-width: 991px){
        margin: 40px 0;
    }

}


#iframe{
    gap: 30px;
    display: flex;
    margin: 30px 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h3{
        font-size: 30px;
    }

    p{
        font-size: 18px;
    }

    iframe{
        max-width: calc(100% - 30px);
    }

}